import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BhTipsComponent} from "./bh-tips.component";
import {TranslocoModule} from "@ngneat/transloco";


@NgModule({
  declarations: [BhTipsComponent],
  exports: [BhTipsComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        NgOptimizedImage
    ]
})
export class BhTipsModule { }
