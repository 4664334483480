<div class="bh-change-multipasport">
  <ng-container *ngIf="interface._cache.upgrade">
    <bh-no-data *ngIf="!interface._cache.upgrade_done" type="upgrade-multipasport" text="{{'global.multipasport' | transloco}}: {{'global.update' | transloco}}"></bh-no-data>
    <bh-no-data *ngIf="interface._cache.upgrade_done" type="upgrade-multipasport" text="{{'global.multipasport' | transloco}}: {{'components.change-multipasport.card.tips.user.title' | transloco}}"></bh-no-data>
    <button class="bh-btn bh-btn-yellow" [disabled]="!interface._cache.upgrade_done" (click)="actionClose()">
      <svg *ngIf="interface._cache.upgrade_done" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <svg *ngIf="!interface._cache.upgrade_done" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span>{{'buttons.continue_prep' | transloco}}</span>
    </button>
  </ng-container>
  <ng-container *ngIf="!interface._cache.upgrade">
    <div class="bh-change-multipasport-container bh-change-multipasport-clear">
      <div class="bh-change-multipasport-header"></div>
      <div class="bh-change-multipasport-body">
        <div class="bh-change-multipasport-title">{{'components.change-multipasport.card.tips.user.title' | transloco}}</div>
        <div class="bh-change-multipasport-text">{{'components.change-multipasport.card.tips.user.text' | transloco}}</div>
      </div>
      <div class="bh-change-multipasport-footer">
        <div class="bh-change-multipasport-footer-charester">
          <img src="{{'./assets/images/staff/photo/personal.svg'}}" alt="{{'staff.personal.name' | transloco}}" />
        </div>
        <div class="bh-change-multipasport-footer-position">
          <p>{{'staff.personal.name' | transloco}}</p>
          <span>{{'staff.personal.position' | transloco}}</span>
        </div>
        <div class="bh-change-multipasport-footer-action"></div>
      </div>
    </div>
    <div class="bh-form !gap-1">
      <div class="bh-form-input-group">
        <div class="bh-form-text bh-form-text-xs">{{'components.change-multipasport.form.email' | transloco}}</div>
        <div class="bh-form-input" [class.bh-form-input-readonly]="interface.submit" [class.bh-form-input-error]="interface._cache.error_fields.includes('email')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
          </svg>
          <input name="email" type="text" role="spinbutton" autocomplete="new-password" placeholder="{{'placeholders.email' | transloco}}" [readonly]="interface.submit" [value]="interface.card.email" (keyup)="actionEnterEmail($event)" (paste)="actionPasteEmail($event)" (focusout)="actionGrantEmail()">
        </div>
        <div class="bh-form-text bh-form-text-xs bh-form-text-error"><ng-container *ngIf="interface._cache.error_fields.includes('email')">{{'errors.change-multipasport.email' | transloco}}</ng-container>&nbsp;</div>
      </div>
      <div class="bh-form-input-group">
        <div class="bh-form-text bh-form-text-xs">{{'components.change-multipasport.form.password' | transloco}}</div>
        <div class="bh-form-input" [class.bh-form-input-readonly]="interface.submit" [class.bh-form-input-error]="interface._cache.error_fields.includes('password')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
          </svg>
          <input name="password" type="text" role="spinbutton" autocomplete="new-password" placeholder="{{'placeholders.password' | transloco}}" [value]="interface.card.password" (keyup)="actionEnterPassword($event)" (paste)="actionPastePassword($event)" (focusout)="actionGrantPassword()" [readonly]="interface.submit">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-auto" tooltip description="{{'tooltips.change-multipasport.generate_password' | transloco}}" (click)="actionGeneratePassword()">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12c0-1.232.046-2.453.138-3.662a4.006 4.006 0 013.7-3.7 48.678 48.678 0 017.324 0 4.006 4.006 0 013.7 3.7c.017.22.032.441.046.662M4.5 12l-3-3m3 3l3-3m12 3c0 1.232-.046 2.453-.138 3.662a4.006 4.006 0 01-3.7 3.7 48.657 48.657 0 01-7.324 0 4.006 4.006 0 01-3.7-3.7c-.017-.22-.032-.441-.046-.662M19.5 12l-3 3m3-3l3 3" />
          </svg>
        </div>
        <div class="bh-form-text bh-form-text-xs bh-form-text-error"><ng-container *ngIf="interface._cache.error_fields.includes('password')">{{'errors.change-multipasport.password' | transloco}}</ng-container>&nbsp;</div>
      </div>
      <button class="bh-btn bh-btn-red mt-4" [disabled]="!actionGrantStart()" (click)="actionIssueCard()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>{{'buttons.issue_user_card' | transloco}}</span>
      </button>
    </div>
  </ng-container>
</div>
