import {
  Action,
  createSelector,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {CategoryModel, PracticesModel} from "../_models/practice.model";
import {AuthNewToken} from "../../auth/_actions/auth.actions";
import {PracticesService} from "../_services/practice.service";
import {
  AddPracticesEducationDay,
  ClearPracticesCategory,
  ClearPracticesPlans,
  ClearPracticeVideo,
  ClearQuizQuestions,
  ClearQuizQuestionsResult,
  FillQuizQuestions,
  LoadPracticesCategory,
  LoadPracticesPlans,
  LoadPracticesQuiz,
  LoadPracticesVideo,
  LoadPracticesWorksheet,
  LoadQuizQuestions,
  UpdateQuizQuestionsResult
} from "../_actions/practice.actions";
import {UserState} from "../../user/_state/user.state";
import {RouterNavigation, RouterState} from "@ngxs/router-plugin";
import {TranslocoService} from "@ngneat/transloco";
import {UserService} from "../../user/_services/user.service";
import {PlansState} from "../../plans/_state/plans.state";
import {CountUpPoints} from "../../user/_actions/user.actions";
import {AuthState} from "../../auth/_state/auth.state";


export const _PracticesDefault: PracticesModel = {
  worksheet: undefined,
  current: [],
  videos: [],
  quiz: [],
  quiz_result: [],
  plans: [],
  education_day: null
};

@State<PracticesModel>({
  name: 'V12_APO_PRACTICE',
  defaults: _PracticesDefault,
})
@Injectable()
export class PracticesState implements NgxsOnInit, NgxsOnChanges {

  constructor(private store: Store, private practicesService: PracticesService, private userService: UserService, private transloco: TranslocoService) {}

  ngxsOnInit(ctx?: StateContext<any>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<PracticesModel>): void {}

  @Selector()
  static selectPractice( state: PracticesModel ) {
    return state;
  }
  @Selector()
  static selectCurrentPractice( state: PracticesModel ) {
    return state.current;
  }
  @Selector()
  static selectCurrentPlans( state: PracticesModel ) {
    return state.plans;
  }

  static selectCurrentPracticeBySlug( _slug: string ) {
    return createSelector([ PracticesState.selectCurrentPractice ], ( _practice: CategoryModel[]) => {
      return _practice.find(x => x.slug === _slug);
    });
  }
  static selectPracticeById(_id: string) {
    return createSelector(
        [PracticesState.selectCurrentPractice],
        (_practice: CategoryModel[]) => {
          function findCategoryById(
              categories: CategoryModel[],
              id: string
          ): CategoryModel | null {
            for (const category of categories) {
              if (category._id === id) {
                return category;
              }
              if (category.children && category.children.length > 0) {
                const found = findCategoryById(category.children as CategoryModel[], id);
                if (found) {
                  return found;
                }
              }
            }
            return null;
          }

          return findCategoryById(_practice, _id);
        }
    );
  }

  @Selector()
  static selectVideoLectures( state: PracticesModel ) {
    return state.videos;
  }
  @Selector()
  static selectQuiz( state: PracticesModel ) {
    return [...state.quiz].sort((a, b) => a.order - b.order);
  }
  @Selector()
  static selectQuizResult(state: PracticesModel) {
    return state.quiz_result;
  }

  @Selector()
  static selectWorksheet( state: PracticesModel ) {
    return state.worksheet;
  }

  @Action(AuthNewToken)
  authNewToken(ctx: StateContext<PracticesModel>) {

  }

  @Action(RouterNavigation)
  async routerNavigation(ctx: StateContext<PracticesModel>, payload) {
    if(payload.routerState.params.worksheet_id) {
      this.store.dispatch(new LoadPracticesWorksheet());
    }
    if(payload.routerState.params.lectures_id) {
      this.store.dispatch(new LoadPracticesVideo());
    }
    if(!payload.routerState.params.lectures_id) {
      ctx.dispatch(new ClearPracticeVideo());
    }

    if(payload.routerState.params.quiz_id) {
      //console.log('QUIZ PAGE');
      //this.store.dispatch(new LoadQuizQuestions(payload.routerState.params.quiz_id));
    }
    if(!payload.routerState.params.quiz_id) {
      ctx.dispatch(new ClearQuizQuestions());
    }


  }


  @Action(LoadPracticesPlans)
  async loadPracticesPlans(ctx: StateContext<PracticesModel>) {
    if (!this.store.selectSnapshot(AuthState.selectAccess)) {
      return;
    }

    const _plans = await this.userService.getEducationPlans().toPromise();
    if (_plans) {
      const _state_current = ctx.getState().current;
      const _state_user = this.store.selectSnapshot(UserState);
      const _state_plans = this.store.selectSnapshot(PlansState);
      const _state_user_prices = _state_user?.prices || [];

      // Получение всех `material_id`, доступных пользователю
      const _state_user_prices_materials_ids = _state_user_prices.flatMap((planStripe) => {
        const plan = _state_plans.list.find((p) => p.stripe === planStripe);
        return plan ? (Array.isArray(plan.premium_material_id) ? plan.premium_material_id : [plan.premium_material_id]) : [];
      });

      // Функция для поиска material_data._id в древовидной структуре
      const findMaterialInTree = (tree: any[], materialId: string): any => {
        for (const node of tree) {
          if (node._id === materialId) {
            return node;
          }
          if (node.children && Array.isArray(node.children)) {
            const found = findMaterialInTree(node.children, materialId);
            if (found) return found;
          }
        }
        return null;
      };

      // Обновление unlock.type для practices
      const updateUnlockTypeInPractices = (practice) => {
        const updatedPractice = { ...practice };

        if (_state_user_prices_materials_ids.includes(updatedPractice.material_data?._id)) {
          // Если `material_id` уже есть в премиальных материалах, делаем его "free"
          updatedPractice.material_data = {
            ...updatedPractice.material_data,
            unlock: { ...updatedPractice.material_data.unlock, type: 'free' },
          };
        } else {
          // Ищем в древовидной структуре `state.current`
          const foundMaterial = findMaterialInTree(_state_current, updatedPractice.material_data?._id);
          if (foundMaterial && foundMaterial.unlock?.type === 'free') {
            updatedPractice.material_data = {
              ...updatedPractice.material_data,
              unlock: { ...updatedPractice.material_data.unlock, type: 'free' },
            };
          }
        }

        return updatedPractice;
      };

      // Обновление плана на основе найденных данных
      const updateUnlockTypeInPlans = (plans) => {
        return plans.map((plan) => ({
          ...plan,
          weeks: plan.weeks.map((week) => ({
            ...week,
            days: week.days.map((day) => ({
              ...day,
              practices: day.practices.map(updateUnlockTypeInPractices),
            })),
          })),
        }));
      };

      const _updated_plans = updateUnlockTypeInPlans(_plans);

      ctx.patchState({
        ...ctx.getState(),
        plans: _updated_plans,
      });
    }
  }


  @Action(ClearPracticesPlans)
  async clearPracticesPlans(ctx: StateContext<PracticesModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {
      return;
    }
    let _state_current = ctx.getState();
    ctx.patchState({
      ..._state_current,
      plans: [],
      education_day: null
    });
  }
  @Action(AddPracticesEducationDay)
  async addPracticesEducationDay(ctx: StateContext<PracticesModel>, payload) {
    let _state_current = ctx.getState();
    if(_state_current.education_day) {
      let _day_practices = _state_current.education_day.practices.reduce((count, practice) => count + (!practice.done ? 1 : 0), 0);

      if(_day_practices == 1 && JSON.stringify(_state_current.education_day) !== JSON.stringify(payload.edu_day)) {
        ctx.dispatch(new CountUpPoints(50));
      }
    }

    ctx.patchState({
      ..._state_current,
      education_day: payload.edu_day
    });
  }

  @Action(ClearPracticesCategory)
  async clearPracticesCategory(ctx: StateContext<PracticesModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {
      return;
    }
    let _state_current = ctx.getState();
    ctx.patchState({
      ..._state_current,
      current: []
    });
  }

  @Action(LoadPracticesCategory)
  async loadPracticesCategory(ctx: StateContext<PracticesModel>) {
    const _state_plans = this.store.selectSnapshot(PlansState);
    const _state_user = this.store.selectSnapshot(UserState);
    const _state_user_prices = _state_user?.prices || [];
    const _state_user_prices_materials_ids = _state_user_prices.flatMap((planStripe) => {
      const plan = _state_plans.list.find((p) => p.stripe === planStripe);
      return plan ? (Array.isArray(plan.premium_material_id) ? plan.premium_material_id : [plan.premium_material_id]) : [];
    });

    const updateUnlockTypeRecursively = (category: CategoryModel): CategoryModel => {
      // Создаем копию category, чтобы избежать изменения исходного объекта
      const updatedCategory = { ...category, unlock: { ...category.unlock } };

      if (_state_user_prices_materials_ids.includes(updatedCategory._id) || _state_user_prices_materials_ids.includes(updatedCategory.parent as string)) {
        updatedCategory.unlock.type = 'free';
        // Устанавливаем unlock.type = 'free' для всех детей рекурсивно
        const setChildrenUnlockFree = (children: (any)[]) => {
          return children.map((child) => {
            if ('_id' in child) {
              const updatedChild = { ...child, unlock: { ...child.unlock, type: 'free' } };
              if (updatedChild.children && Array.isArray(updatedChild.children)) {
                updatedChild.children = setChildrenUnlockFree(updatedChild.children);
              }
              return updatedChild;
            }
            return child;
          });
        };
        if (updatedCategory.children && Array.isArray(updatedCategory.children)) {
          updatedCategory.children = setChildrenUnlockFree(updatedCategory.children);
        }
      }

      if (updatedCategory.children && Array.isArray(updatedCategory.children)) {
        updatedCategory.children = updatedCategory.children.map((child) => {
          if ('_id' in child) {
            return updateUnlockTypeRecursively(child as CategoryModel);
          }
          return child;
        });
      }

      return updatedCategory;
    };

    let _practices_current_state = ctx.getState();
    if (_state_user.settings.grade !== null) {
      let _practices_by_grade = await this.practicesService.getGradePractice(_state_user.settings.grade._id);

      if (_practices_by_grade) {
        let _practices_by_grade_with_user_premium = _practices_by_grade.map((category) => updateUnlockTypeRecursively(category));
        if (JSON.stringify(_practices_current_state.current) !== JSON.stringify(_practices_by_grade_with_user_premium)) {
          ctx.patchState({
            ..._practices_current_state,
            current: _practices_by_grade_with_user_premium,
          });
        }
      }
    }
  }

  @Action(LoadPracticesVideo)
  async loadPracticesVideo(ctx: StateContext<PracticesModel>) {
    let _state_current = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    const _state_router = this.store.selectSnapshot(RouterState);
    let _videos = await this.practicesService.getPracticeVideos(_state_router.state.params.lectures_id);
    if(_videos.length > 0) {
      ctx.patchState({
        ..._state_current,
        videos: _videos
      });
    }
  }

  @Action(ClearPracticeVideo)
  async clearPracticeVideo(ctx: StateContext<PracticesModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {
      return;
    }
    let _state_current = ctx.getState();
    ctx.patchState({
      ..._state_current,
      videos: []
    });
  }

  @Action(LoadPracticesWorksheet)
  async loadPracticesWorksheet(ctx: StateContext<PracticesModel>) {
    let _state_current = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    const _state_router = this.store.selectSnapshot(RouterState);
/*    let _worksheet = await this.practicesService.getPracticeWorksheet(_state_router.state.params.worksheet_id);
    if(_worksheet) {
      ctx.patchState({
        ..._state_current,
        worksheet: _worksheet
      });
    }*/

    const _state_worksheets = _state_current.current.find(_cur => _cur.slug === 'worksheets');
    let _state_worksheet = this._findWorksheetById(_state_worksheets, _state_router.state.params.worksheet_id);
    if(_state_worksheet) {
      ctx.patchState({
        ..._state_current,
        worksheet: _state_worksheet
      });
    }
  }

  @Action(LoadPracticesQuiz)
  async loadPracticesQuiz(ctx: StateContext<PracticesModel>) {
    let _state_current = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    const _state_router = this.store.selectSnapshot(RouterState);
    let _quiz = await this.practicesService.getPracticeQuiz(_state_router.state.params.quiz_id);
    if(_quiz.length > 0) {
      ctx.patchState({
        ..._state_current,
        quiz: _quiz
      });
    }
  }

  @Action(LoadQuizQuestions)
  async loadQuizQuestions(ctx: StateContext<PracticesModel>, payload) {
    let _quiz = await this.practicesService.getPracticeQuiz(payload.category_id);
    if(_quiz.length > 0) {
      this.store.dispatch(new FillQuizQuestions(_quiz));
    }
  }

  @Action(FillQuizQuestions)
  fillQuizQuestions(ctx: StateContext<PracticesModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      quiz: payload.questions,
    });
  }
  @Action(ClearQuizQuestions)
  clearQuizQuestions(ctx: StateContext<PracticesModel>) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      quiz: [],
    });
  }


  @Action(UpdateQuizQuestionsResult)
  updateQuizQuestionsResult(ctx: StateContext<PracticesModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      quiz_result: payload.result
    });
  }

  @Action(ClearQuizQuestionsResult)
  clearQuizQuestionsResult(ctx: StateContext<PracticesModel>) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      quiz_result: []
    });
  }


  _findWorksheetById(rootCategory: CategoryModel, targetId: string): CategoryModel | null {
    // Создаем стек для хранения категорий
    const stack: CategoryModel[] = [rootCategory];

    // Перебираем элементы стека
    while (stack.length > 0) {
      const currentCategory = stack.pop();

      if (!currentCategory) continue;

      // Проверяем, соответствует ли текущий объект целевому ID
      if (currentCategory._id === targetId) {
        return currentCategory;
      }

      // Если есть дети, добавляем их в стек для дальнейшего поиска
      if (currentCategory.children) {
        stack.push(...currentCategory.children as CategoryModel[]);
      }
    }

    // Если объект не найден, возвращаем null
    return null;
  }



}
