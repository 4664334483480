import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'bh-no-data',
  templateUrl: './bh-no-data.component.html',
  styleUrls: ['./bh-no-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BhNoDataComponent implements OnInit {
  @Input() text: string;
  @Input('type') set _type(_type) {
    if (_type) {
      this.interface.type = _type;
    }
  }
  interface = {
    type: ''
  }

  constructor() { }

  ngOnInit(): void {
  }

}
