import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BhChangeMultipasportComponent} from "./bh-change-multipasport.component";
import {AppTranslocoRootModule} from "../../../app.transloco.module";
import {BhTooltipModule} from "../bh-tooltip/bh-tooltip.module";
import {BhNoDataModule} from "../bh-no-data/bh-no-data.module";


@NgModule({
  declarations: [BhChangeMultipasportComponent],
  exports: [BhChangeMultipasportComponent],
    imports: [
        CommonModule,
        AppTranslocoRootModule,
        BhTooltipModule,
        BhNoDataModule
    ]
})
export class BhChangeMultipasportModule { }
