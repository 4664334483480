import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {Store} from "@ngxs/store";
import {AppModel} from "./_stores/settings/_models/settings.model";
import {SettingsState} from "./_stores/settings/_state/settings.state";
import {NavigationEnd, Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {SeoService} from "./_services/seo.service";
import {ScrollService} from "./_services/scroll.service";


@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  interface: AppModel  = {
    loading: false,
    settings: undefined,
    _cache: {
      subscribe: {
        settings: undefined,
        router: undefined,
      }
    }
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef,private router: Router, @Inject(DOCUMENT) private _document: Document, private seoService: SeoService, private scrollService: ScrollService) {}



  ngOnInit(): void {
    this.initSettings();
    this.scrollBehavior();
  }

  ngOnDestroy(): void {
    this.interface._cache.subscribe.settings.unsubscribe();
    this.interface._cache.subscribe.router.unsubscribe();
  }


  initSettings(): void {
    this.interface._cache.subscribe.settings = this.store.select(SettingsState.selectSettings).subscribe((_settings: any)=> {
      this.interface.settings = _settings;
      this.cdr.detectChanges();
    });
  }

  scrollBehavior(): void {
    this.interface._cache.subscribe.router = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this._document.body.scrollTop = 0;
      }
    });
  }




}
