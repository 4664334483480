import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BhInviteComponent} from "./bh-invite.component";
import {BhTooltipModule} from "../bh-tooltip/bh-tooltip.module";
import {AppTranslocoRootModule} from "../../../app.transloco.module";



@NgModule({
  declarations: [BhInviteComponent],
  exports: [BhInviteComponent],
  imports: [
    CommonModule,
    BhTooltipModule,
    AppTranslocoRootModule
  ]
})
export class BhInviteModule { }
