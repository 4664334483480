import {Injectable} from '@angular/core';
import {CanMatch, Route, Router, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {SettingsState} from "../_stores/settings/_state/settings.state";
import {AuthState} from "../_stores/auth/_state/auth.state";
import {UserState} from "../_stores/user/_state/user.state";

@Injectable({
  providedIn: 'root',
})
export class MatchUnsetGradeGuard implements CanMatch {

  constructor(private store: Store, private router: Router) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !this.store.selectSnapshot(UserState.selectGrade);
  }

}
