import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Actions, ofActionDispatched, Store} from "@ngxs/store";
import {WebSocketDisconnected} from "@ngxs/websocket-plugin";
import {AuthState} from "../_stores/auth/_state/auth.state";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.select(AuthState.selectAuthToken).subscribe({
      next: token => {
        if (token) {
          request = request.clone({
            setHeaders: {
              'Authorization': 'Bearer ' + token,
            }
          });
        }
      }
    });
    return next.handle(request);
  }
}
