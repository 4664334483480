import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Store} from "@ngxs/store";
import {TranslocoService} from "@ngneat/transloco";
import {ModalCloseAction} from "../../../_stores/modal/_actions/modal.actions";

@Component({
  selector: 'bh-leave-review',
  templateUrl: './bh-leave-review.component.html',
  styleUrls: ['./bh-leave-review.component.scss']
})
export class BhLeaveReviewComponent implements OnInit {
  interface: {
    review: {
      title: string;
      description: string;
    },
    submit: boolean,
    platform: any,
    _cache: any
  } = {
    review: {
      title: '',
      description: '',
    },
    submit: false,
    platform: undefined,
    _cache: {
      load_data: false,
      update_data: false,
      error_fields:[],
      subscribe: {
        user: undefined,
        router: undefined
      }
    }
  }



  constructor(private store: Store, private cdr: ChangeDetectorRef, private transloco: TranslocoService) { }

  ngOnInit(): void {
  }

  actionEnterReviewTitle(event): void {
    this.interface.review.title = event.target.value.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()).replace(/\bEla\b/g, 'ELA').replace(/\bIi\b/g, 'II');
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'title');
  }
  actionPasteReviewTitle(event): boolean {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData?.getData('text');
    if(pastedText) {
      this.interface.review.title = pastedText.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()).replace(/\bEla\b/g, 'ELA').replace(/\bIi\b/g, 'II');
      this.actionGrantReviewTitle();
    }
    return false;
  }
  actionGrantReviewTitle(): boolean {
    let _grant = true;
    if (this.interface.review?.title.length < 3) {
      _grant = false;
    }
    if(!_grant) {
      this.interface._cache.error_fields.push('title');
    }
    return _grant;
  }
  actionEnterDescription(event) {
    this.interface.review.description = event.target.value.replace(/<[^>]*>|&[^;]+;/g, '');
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'description');
  }
  actionGrantReviewDescription(): boolean {
    let _grant = true;
    if (this.interface.review?.description.length < 10) {
      _grant = false;
    }
    if(!_grant) {
      this.interface._cache.error_fields.push('description');
    }
    return _grant;
  }
  preventEnter(event) {
    const keyCode = event.which || event.keyCode;
    if (keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
    }
  }

  actionGrantAddReview(): boolean {
    let _grant = true;
    if (this.interface.review?.title.length < 3) {
      _grant = false;
    }
    if (this.interface.review?.description.length < 10) {
      _grant = false;
    }
    return _grant
  }

  actionAddReview(): void {
    this.interface.review = {
      title: '',
      description: '',
    };
    this.interface.submit = true;
  }
  actionCloseModal(): void {
    this.store.dispatch(new ModalCloseAction());
  }

}
