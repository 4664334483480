import {ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {DOCUMENT} from "@angular/common";
import {TipsModel} from "../../../_stores/tips/_models/tips.model";
import {TipsState} from "../../../_stores/tips/_state/tips.state";
import {TipsCloseAction} from "../../../_stores/tips/_actions/tips.actions";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {CountUpPoints} from "../../../_stores/user/_actions/user.actions";

@Component({
  selector: 'bh-tips',
  templateUrl: './bh-tips.component.html',
  styleUrls: ['./bh-tips.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'tips', alias: 'tips'  } }],
})
export class BhTipsComponent implements OnInit, OnDestroy {
  interface: TipsModel = {
    show: false,
    options: {
      type: undefined,
      count: 0,
      image: undefined
    },
    _cache: {
      subscribe: {
        tips: undefined
      }
    },
  }
  constructor(private store: Store, private cdr: ChangeDetectorRef, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.initTipsStatus();
  }
  ngOnDestroy(): void {
    if (!this.interface || !this.interface._cache) {return}
    Object.keys(this.interface._cache.subscribe).forEach((_subscribe) => {
      if (this.interface._cache.subscribe[_subscribe]) {
        this.interface._cache.subscribe[_subscribe].unsubscribe();
      }
    });
  }

  initTipsStatus(): void {
    this.interface._cache.subscribe.tips = this.store.select(TipsState.selectStatus).subscribe((res: any)=> {
      this.interface = {...this.interface, ...res};
      if(this.interface?.show) {
        this.document.body.classList.add("tips-show");
        if(this.interface.options.type == 'strike') {
          this.actionPlayStrikeSound();
          setTimeout(() => {
            this.interface._cache._audio.pause();
            this.store.dispatch(new TipsCloseAction());
            this.store.dispatch(new CountUpPoints(20));
          }, 2000);
        }
        if(this.interface.options.type == 'points') {
          setTimeout(() => {
            this.store.dispatch(new TipsCloseAction());
          }, 1500);
        }
        if(this.interface.options.type == 'award') {
          this.actionPlayAwardSound();
          setTimeout(() => {
            this.interface._cache._audio.pause();
            this.store.dispatch(new TipsCloseAction());
          }, 4000);
        }
        if(this.interface.options.type == 'done') {
          this.actionPlayDoneSound();
          setTimeout(() => {
            this.store.dispatch(new TipsCloseAction());
          }, 1500);
        }
      } else {
        this.document.body.classList.remove("tips-show");
      }

      this.cdr.detectChanges();
    });
  }


  actionPlayStrikeSound(): void {
    this.interface._cache._audio = new Audio();
    this.interface._cache._audio.src = 'assets/sounds/strike.mp3';
    this.interface._cache._audio.load();
    this.interface._cache._audio.play();
  }

  actionPlayAwardSound(): void {
    this.interface._cache._audio = new Audio();
    this.interface._cache._audio.src = 'assets/sounds/award.mp3';
    this.interface._cache._audio.load();
    this.interface._cache._audio.play();
  }
  actionPlayDoneSound(): void {
    const sound = Math.floor(Math.random() * 13);
    this.interface._cache._audio = new Audio();
    this.interface._cache._audio.src = 'assets/sounds/done/done_' + (sound+1) + '.mp3';
    this.interface._cache._audio.load();
    this.interface._cache._audio.play();
  }


  actionCloseTips(e?): void {
    if(!e || e && e.target.className === 'bh-tip') {
      this.store.dispatch(new TipsCloseAction());
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'Escape' && this.interface?.show) {
      this.store.dispatch(new TipsCloseAction());
    }
  }

}
