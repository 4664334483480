import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BhLeaveReviewComponent} from "./bh-leave-review.component";
import {TranslocoPipe} from "@ngneat/transloco";


@NgModule({
  declarations: [BhLeaveReviewComponent],
  exports: [BhLeaveReviewComponent],
  imports: [
    CommonModule,
    TranslocoPipe,
    NgOptimizedImage
  ]
})
export class BhLeaveReviewModule { }
