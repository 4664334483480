import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";
import {Store} from "@ngxs/store";
import {ChangeLanguageAction} from "../../../_stores/settings/_actions/settings.actions";
import {ModalCloseAction} from "../../../_stores/modal/_actions/modal.actions";


@Component({
  selector: 'bh-language-select',
  templateUrl: './bh-language-select.component.html',
  styleUrls: ['./bh-language-select.component.scss'],
})
export class BhLanguageSelectComponent implements OnInit {

  constructor(private store: Store, private cdr: ChangeDetectorRef, private transloco: TranslocoService) { }

  ngOnInit(): void {
  }


  actionChangeLanguage(lang): void {
    this.transloco.setActiveLang(lang);
    this.store.dispatch(new ChangeLanguageAction(lang));
    this.store.dispatch(new ModalCloseAction());
  }
}
