import {UserModel} from "../../user/_models/user.model";

export class AuthAllowAccess  {
  public static readonly type = '[Auth] AuthAllowAccess';
  constructor() {}
}
export class AuthDisallowAccess  {
  public static readonly type = '[Auth] AuthDisallowAccess';
  constructor() {}
}
export class AuthNewToken  {
  public static readonly type = '[Auth] AuthNewToken';
  constructor(public token?: string) {}
}
export class AuthAllowPinAccess  {
  public static readonly type = '[Auth] AuthAllowPinAccess';
  constructor() {}
}
export class AuthDisallowPinAccess  {
  public static readonly type = '[Auth] AuthDisallowPinAccess';
  constructor() {}
}
export class AuthChangeAccessWithPin  {
  public static readonly type = '[Auth] AuthChangeAccessWithPin';
  constructor(public check: boolean) {}
}


export class AuthUpdateToken  {
  public static readonly type = '[Auth] AuthUpdateToken';
  constructor(public token?: string) {}
}
export class AuthStatus  {
  public static readonly type = '[Auth] AuthStatus';
  constructor() {}
}
export class AuthListing  {
  public static readonly type = '[Auth] AuthListing';
  constructor() {}
}
export class AuthRemindPin  {
  public static readonly type = '[Auth] AuthRemindPin';
  constructor() {}
}

export class AuthSwitchUserToChild  {
  public static readonly type = '[Auth] AuthSwitchUserToChild';
  constructor(public child: UserModel) {}
}
