import {API} from "../../api";
import {HttpClient} from "@angular/common/http";
import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {UserModel} from "../_models/user.model";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  isBrowser: boolean;

  constructor(private http: HttpClient,  @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public create(user: any) {
    return this.http.post<any>(API.url('auth/sign-up'), user);
  }
  public get(){
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<UserModel>(API.url('user'+randomHash));
  }
  public getEducationPlans(){
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('user/education'+randomHash));
  }
  public createEducationPlan(){
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('user/education/create'+randomHash));
  }
  public upgrade(auth: any, user: any) {
    return this.http.post<any>(API.url('user/upgrade'), {auth, user});
  }

  public updateGrade(grade_id: any) {
    return this.http.post(API.url('user/settings/grade'), {grade: grade_id});

  }
  public updateSound(sound_status: boolean) {
    return this.http.post(API.url('user/settings/sound'), {sound: sound_status});
  }
  public updateLanguage(language: string) {
    return this.http.post(API.url('user/settings/language'), {});
  }
  public updateAccountData(new_data: any) {
    return this.http.post(API.url('user'), {new_data});
  }
  public updateSpendPoints(count: number) {
    return this.http.post(API.url('user/score/points'), {count});
  }
  public updateSpendStar(count: number) {
    return this.http.post(API.url('user/score/stars'), {count});
  }
  public updateEnergy(energy: number) {
    return this.http.post(API.url('user/score/energy'), {energy});
  }
  public updateLife(life: number) {
    return this.http.post(API.url('user/score/life'), {life});
  }

  public getOrders() {
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('user/orders'+randomHash));
  }
  public getDefaultAwards() {
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('awards', true));
  }

  public getStripeCustomer() {
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('user/stripe/customer'+randomHash));
  }
  public getStripeInvoices() {
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('user/stripe/invoices'+randomHash));
  }
  public createPaymentIntent(amount: any) {
    return this.http.post<any>(API.url('user/stripe/payment-intent'), {amount});
  }
  public checkoutStripe(stripe: any) {
    return this.http.post<any>(API.url('user/stripe/checkout'), {stripe});
  }

  public getStripeSubscriptions() {
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('user/stripe/subscriptions'+randomHash));
  }
  public startStripeSubscription(stripe: any) {
    return this.http.post<any>(API.url('user/stripe/subscription/create'), {stripe});
  }
  public upgradeStripeSubscription(stripe: any) {
    return this.http.post<any>(API.url('user/stripe/subscription/upgrade'), {stripe});
  }
  public pauseStripeSubscription(subscribe_id: any) {
    return this.http.post<any>(API.url('user/stripe/subscription/pause'), {subscribe_id});
  }
  public resumeStripeSubscription(subscribe_id: any) {
    return this.http.post<any>(API.url('user/stripe/subscription/resume'), {subscribe_id});
  }
  public cancelStripeSubscription(subscribe_id: any) {
    return this.http.post<any>(API.url('user/stripe/subscription/cancel'), {subscribe_id});
  }


  public getTimers() {
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('user/timers'+randomHash)).toPromise();
  }
  public startTimer(timer_data: { type: string, start: any; end: any; }) {
    return this.http.put(API.url('user/timers'), timer_data);
  }
  public updateTimer(timer_data: { type: string, start: any; end: any; }) {
    return this.http.post(API.url('user/timers'), timer_data);
  }





  public sseMessages(){
    if(this.isBrowser) {
      return new EventSource(API.url('user/stream'));
    } else {
      return undefined;
    }

  }


}
