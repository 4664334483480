import {Component, Input, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'bh-tooltip',
  templateUrl: './bh-tooltip.component.html',
  styleUrls: ['./bh-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BhTooltipComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() position: string; // 'left' | 'right' | 'above' | 'below'
  @Input() contentTemplate: TemplateRef<any>;
  @Input() _id: string;
  constructor() { }

  ngOnInit(): void {
  }

}
