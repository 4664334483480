<div class="bh-form pb-4">
  <div class="bh-form-input-group">
    <div class="bh-form-text bh-form-text-3xl font-bold self-center mt-6 !text-stone-50" style="hyphens: auto">{{'components.invite.friends.invite' | transloco}}</div>
    <div class="bh-form-text my-4 px-0 !text-stone-100 text-justify" style="hyphens: auto">{{'components.invite.friends.empty.text' | transloco}}</div>
    <img src="assets/images/animation/lines_storm_yellow.svg" alt="lines" class="mx-auto w-72 !aspect-[300/10]"/>
  </div>
  <div class="bh-form-input-group">
    <div class="bh-form-input">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" (click)="actionClipboardInviteLink()">
        <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
      </svg>
      <input name="invite" type="text" [value]="interface.invite_link" readonly>
    </div>
  </div>
  <button class="bh-btn bh-btn-red" (click)="actionClipboardInviteLink()">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
    </svg>
    <span>{{'buttons.copy_link' | transloco}}</span>
  </button>
</div>
