import {CartProductModel} from "../_models/cart.model";

export class AddProductToCart  {
  public static readonly type = '[Cart] AddProductToCart';
  constructor(public product: CartProductModel) {}
}

export class ClearProductsFromCart  {
  public static readonly type = '[Cart] ClearProductsFromCart';
  constructor() {}
}
export class DeleteProductFromCart  {
  public static readonly type = '[Cart] DeleteProductFromCart';
  constructor(public product: CartProductModel) {}
}
