import {environment} from "../../environments/environment";

export class API {
  static url(method: string, assets?: boolean): string {
    const _api_endpoint = environment.api_endpoint;
    //const _api_endpoint = '';
    let _request_url = `${_api_endpoint}/api/${method}`;
    if(assets) {
      _request_url = `${_api_endpoint}/assets/json/self/${method}.json`;
    }
    //console.log("REQUEST URL", _request_url);
    return _request_url;
  }
  static urlWithHash(method: string, hash?: boolean): string {
    const _api_endpoint = environment.api_endpoint;
    let _request_url = `${_api_endpoint}/api/${method}`;
    if(hash) {
      const _query_hash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
      _request_url += _query_hash;
    }
    return _request_url;
  }
}
