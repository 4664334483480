import {Injectable} from '@angular/core';
import {CanMatch, Route, Router, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {UserState} from "../_stores/user/_state/user.state";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class MatchProtectPageGuard implements CanMatch {

  constructor(private store: Store, private router: Router) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const _user_email = this.store.selectSnapshot(UserState.selectEmail);
    return environment.managers.includes(_user_email);
  }

}
