export class InitWorkbooksItems  {
  public static readonly type = '[Workbooks] InitWorkbooksItems';
  constructor() {}
}
export class InitWorkbooksLocalItems  {
  public static readonly type = '[Workbooks] InitWorkbooksLocalItems';
  constructor() {}
}
export class InitWorkbooksReviews  {
  public static readonly type = '[Workbooks] InitWorkbooksReviews';
  constructor() {}
}


export class InitWorkbookBve  {
  public static readonly type = '[Workbooks] InitWorkbookBve';
  constructor(public id: number) {}
}


export class LoadBookPages {
  static readonly type = '[Workbooks] LoadBookPages';
  constructor(public book_id: string, public page_number: number) {}
}
export class ClearBookPages {
  static readonly type = '[Workbooks] ClearBookPages';
}

export class LoadBookVideos {
  static readonly type = '[Workbooks] LoadBookVideos';
  constructor(public book_id: string) {}
}
export class ClearBookVideos {
  static readonly type = '[Workbooks] ClearBookVideos';
}
