import {CSP_NONCE, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeEN from '@angular/common/locales/en';
import localeRU from '@angular/common/locales/ru';
import localeUA from '@angular/common/locales/uk';
import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxsModule} from '@ngxs/store';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsRouterPluginModule, RouterStateSerializer} from '@ngxs/router-plugin';
import {NgxsStoragePluginModule} from "@ngxs/storage-plugin";

import {environment} from '../environments/environment';
import {AuthInterceptor} from "./_interceptors/auth.interceptor";
import {LanguageInterceptor} from "./_interceptors/language.interceptor";
import {NoCacheInterceptor} from "./_interceptors/nocache.interceptor";

import {AppTranslocoRootModule} from "./app.transloco.module";
import {AppPipesModule} from "./app.pipes.module";
import {AppRoutingModule} from './app.routing.module';
import {AppSharedModule} from "./app.shared.module";
import {AppComponent} from './app.component';

import {BhModalModule} from "./_ui/components/bh-modal/bh-modal.module";
import {BhTipsModule} from "./_ui/components/bh-tips/bh-tips.module";

import {CustomRouterStateSerializer} from "./_stores/router/router-state.serializer";
import {UserState} from "./_stores/user/_state/user.state";
import {SettingsState} from "./_stores/settings/_state/settings.state";
import {AuthState} from "./_stores/auth/_state/auth.state";
import {ModalState} from "./_stores/modal/_state/modal.state";
import {CrumbsState} from "./_stores/crumbs/_state/crumbs.state";
import {TransferHttpCacheModule} from "@nguniversal/common";
import {NgxsWebsocketPluginModule} from "@ngxs/websocket-plugin";
import {InlineSVGModule} from "ng-inline-svg-2";
import {WorksheetsState} from "./_stores/worksheets/_state/worksheets.state";
import {WorkbooksState} from "./_stores/workbooks/_state/workbooks.state";
import {TranslateModule} from "@ngx-translate/core";
import {LocaleProvider} from "./_providers/locale.provider";
import {CartState} from "./_stores/cart/_state/cart.state";
import {DefaultUrlSerializer, UrlSerializer, UrlTree} from "@angular/router";
import {BlogState} from "./_stores/blog/_state/blog.state";
import {GradesState} from "./_stores/grades/_state/grades.state";
import {PracticesState} from "./_stores/practice/_state/practice.state";
import {AssignmentsBuilderState} from "./_stores/assignments-builder/_state/assignments-builder.state";
import {PlansState} from "./_stores/plans/_state/plans.state";
import {TipsState} from "./_stores/tips/_state/tips.state";


registerLocaleData(localeEN, 'en');
registerLocaleData(localeUA, 'ua');
registerLocaleData(localeRU, 'ru');

export class TrailingSlashUrlSerializer extends DefaultUrlSerializer {
  serialize(tree: UrlTree): string {
    return this._withTrailingSlash(super.serialize(tree));
  }

  private _withTrailingSlash(url: string): string {
    const splitOn = url.indexOf('?') > - 1 ? '?' : '#';
    const pathArr = url.split(splitOn);

    if (!pathArr[0].endsWith('/')) {
      let fileName: string = url.substring( url.lastIndexOf('/') + 1);
      if (fileName.indexOf('.') === -1) {
        pathArr[0] += '/';
      }
    }
    return pathArr.join(splitOn);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({appId: 'argoprep_online'}),
/*    ServiceWorkerModule.register('bha.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),*/
    NgxsModule.forRoot([AssignmentsBuilderState,GradesState,BlogState,CartState,WorksheetsState,WorkbooksState,UserState,CrumbsState,PracticesState,PlansState,AuthState,TipsState,ModalState,SettingsState], {developmentMode: environment.storeDevtools}),
    NgxsModule.forFeature([]),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({key: [AssignmentsBuilderState, CartState, PracticesState,UserState,AuthState,SettingsState]}),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsWebsocketPluginModule.forRoot(),
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot(),
    HttpClientModule,
    TransferHttpCacheModule,
    AppTranslocoRootModule,
    AppPipesModule,
    AppRoutingModule,
    AppSharedModule,
    BhModalModule,
    BhTipsModule
  ],
  providers: [
    LocaleProvider,
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true},
    { provide: CSP_NONCE, useValue: (new Date().getTime() / 1000 | 0).toString(16) + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
        return (Math.random() * 16 | 0).toString(16);
      }).toLowerCase()},
    provideClientHydration(),
    { provide: UrlSerializer, useClass: TrailingSlashUrlSerializer }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
