import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanMatch,
  Route,
  Router,
  RouterStateSnapshot, UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {SettingsState} from "../_stores/settings/_state/settings.state";
import {AuthState} from "../_stores/auth/_state/auth.state";

@Injectable({
  providedIn: 'root',
})
export class MatchPublicPageGuard implements CanMatch {

  constructor(private store: Store, private router: Router) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !this.store.selectSnapshot(AuthState.selectAccess);
  }

}
