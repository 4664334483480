import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BhNoDataComponent} from "./bh-no-data.component";



@NgModule({
  declarations: [BhNoDataComponent],
  exports: [BhNoDataComponent],
    imports: [
        CommonModule,
        NgOptimizedImage
    ]
})
export class BhNoDataModule { }
