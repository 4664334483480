import {Injectable} from '@angular/core';
import {CanMatch, Route, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MatchPublicPageSerieGuard implements CanMatch {

  constructor() {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const _segment = segments[1]? segments[1].path : segments[0].path;

    return [
      "social_studies",
      "brain_hunter",
      "kids_summer_academy",
      "spelling",
      "common_core_spanish",
      "introducing_math_science",
      "pre_school",
      "science",
      "kids_winter_academy",
      "math_practice",
      "common_core",
      "introducing_math",
      "shsat"
    ].includes(_segment);
  }

}
