import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BhLanguageSelectComponent} from "./bh-language-select.component";
import {AppTranslocoRootModule} from "../../../app.transloco.module";



@NgModule({
  declarations: [BhLanguageSelectComponent],
  exports: [BhLanguageSelectComponent],
  imports: [
    CommonModule,
    AppTranslocoRootModule
  ]
})
export class BhLanguageSelectModule { }
