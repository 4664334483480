export class InitTimers  {
  public static readonly type = '[Timer] InitTimers';
  constructor() {}
}
export class StartTimer  {
  public static readonly type = '[Timer] StartTimer';
  constructor(public type: string) {}
}
export class UpdateTimer  {
  public static readonly type = '[Timer] UpdateTimer';
  constructor(public type: string, public start: Date) {}
}
export class StopTimer  {
  public static readonly type = '[Timer] StopTimer';
  constructor(public type: string) {}
}

export class WatchTimers  {
  public static readonly type = '[Timer] WatchTimers';
  constructor() {}
}

