import {API} from "../../api";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ReviewModel, WorkbookModel} from "../_models/workbooks.model";
import {EBookPageModel} from "../_models/e-book-page.model";

@Injectable({
  providedIn: 'root',
})
export class WorkbooksService {

  constructor(private http: HttpClient) {
  }

  public getWorkbooks(self?: boolean) {
    const _url = API.url('workbooks', self);
    return this.http.get<WorkbookModel[]>(_url).toPromise();
  }

  public getBveWorkbookById(_id: string | number) {
    const _url = API.url('bve/'+_id+'', true);
    return this.http.get<WorkbookModel>(_url).toPromise();
  }


  public getWorkbookReviewsById(_id: string) {
    const _url = API.url('workbooks/'+_id+'/reviews');
    return this.http.get<ReviewModel[]>(_url).toPromise();
  }

  public getBookPages(book_id, page_number) {
    return this.http.get<EBookPageModel[]>(API.url('workbooks/e_book/'+book_id+'/'+page_number));
  }


}
