import {Injectable} from '@angular/core';
import {
  CanMatch,
  Route,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MatchPublicPageAuthActionsGuard implements CanMatch {

  constructor() {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return ['forgot', 'create'].includes(segments[0].path);
  }

}
