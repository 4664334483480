import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {StripeCardNumberComponent, StripeService} from "ngx-stripe";
import {UserModel} from "../../../_stores/user/_models/user.model";
import {StripeCardElementOptions, StripeElementsOptions} from "@stripe/stripe-js";
import {Store} from "@ngxs/store";
import {UserService} from "../../../_stores/user/_services/user.service";
import {UserState} from "../../../_stores/user/_state/user.state";
import {StripeElementLocale} from "@stripe/stripe-js/types/stripe-js/elements-group";
import {ModalCloseAction} from "../../../_stores/modal/_actions/modal.actions";
import {CartState} from "../../../_stores/cart/_state/cart.state";
import {CartModel} from "../../../_stores/cart/_models/cart.model";
import {Router} from "@angular/router";
import {ClearProductsFromCart, DeleteProductFromCart} from "../../../_stores/cart/_actions/cart.actions";
import {AuthState} from "../../../_stores/auth/_state/auth.state";
import {updateUser} from "../../../_stores/user/_actions/user.actions";

@Component({
  selector: 'bh-minicart',
  templateUrl: './bh-minicart.component.html',
  styleUrls: ['./bh-minicart.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'components/minicart', alias: 'components.minicart' }}]
})
export class BhMinicartComponent  implements OnInit, OnDestroy {
  @Input() on_page: boolean = false;
  @Input() checkout: boolean = false;

  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
  interface: {
    auth: boolean,
    submit: boolean,
    user: UserModel,
    cart: CartModel,
    states: any[],
    tax: number,
    payment_active: boolean,
    payment: {
      stripe: {
        customer_id: string,
        elementsOptions: StripeElementsOptions,
        cardOptions: StripeCardElementOptions
      }
    },
    _cache: any
  }
  =
      {
        auth: false,
    submit: false,
    user: undefined,
    cart: undefined,
    states: [],
    tax: 0,
    payment_active: true,
    payment: {
      stripe: {
        customer_id: undefined,
        elementsOptions: {
          locale: 'en'
        },
        cardOptions: {
          style: {
            base: {
              fontFamily: 'Montserrat, sans-serif',
              '::placeholder': {
                "fontSize": '0.75rem',
                "lineHeight": '1rem',
                "fontWeight": 600,
                color: '#9ca3af',
              },
            },
          }
        }
      }
    },
    _cache: {
      cart: undefined,
      payment: false,
      payment_complete: false,
      payment_not_complete: false,
      payment_order: undefined,
      subscribe: {
        user: undefined,
        cart: undefined,
      },
      error_fields: [],
      error_messages: {
        number: '',
        expire: '',
        cvc: '',
      },
      card: {
        number: false,
        expire: false,
        cvc: false,
      }
    }
  }

  constructor(private store: Store, private cdr: ChangeDetectorRef, private userService: UserService, private stripeService: StripeService, private router: Router) {
  }

  ngOnInit(): void {
    this.initUser();
    this.initCart();
    if(this.checkout) {
      this.actionGoPayment();
      this.initStates();
    }
  }

  ngOnDestroy(): void {
    if (!this.interface._cache) {
      return
    }
    Object.keys(this.interface._cache.subscribe).forEach((_subscribe) => {
      if (this.interface._cache.subscribe[_subscribe]) {
        this.interface._cache.subscribe[_subscribe].unsubscribe();
      }
    });
  }

  initUser(): void {
    this.interface._cache.subscribe.user = this.store.select(UserState.selectUser).subscribe((_user: any) => {
      this.interface.auth = this.store.selectSnapshot(AuthState.selectAccess);
      this.interface.user = {..._user};
      this.interface.payment.stripe.elementsOptions.locale = this.interface.user.settings.language as StripeElementLocale;
      if(this.interface.user.role != 'guest') {
        this.initUserStripeCustomer();
      }
      this.cdr.detectChanges();
    });
  }
  initCart(): void {
    this.interface._cache.subscribe.cart = this.store.select(CartState.selectCartPopulateProducts).subscribe((_cart) => {
      this.interface.cart = _cart.cart;
      this.interface._cache.cart = _cart.cache;
      if(this.interface.cart.products.length == 0 && !this.interface._cache.payment_complete && !this.interface._cache.payment_not_complete) {
        this.store.dispatch(new ModalCloseAction());
      }
      this.initTotalCost();
      this.initTotalProducts();
      this.cdr.markForCheck();
    });
  }
  initStates(): void {
    this.interface._cache.subscribe.states = this.store.select(UserState.selectDefaultStates).subscribe((_states: any) => {
      this.interface.states = _states;
      this.cdr.detectChanges();
    });
  }

  initUserStripeCustomer(): void {
    this.userService.getStripeCustomer().subscribe(_customer => {
      this.interface.payment.stripe.customer_id = _customer.customer_id;
    });
  }

  initTotalCost(): void {
    if (this.interface.user.billing.state === 'NY') {
      this.interface.tax = 8.85 / 100; // Хранение в формате доли для расчетов
    }
    else {
      this.interface.tax = 0;
    }

    let totalCost = 0;

    // Рассчитываем общую стоимость всех товаров в корзине
    for (const product of this.interface.cart.products) {
      totalCost += product.count * product.cost;
    }

    // Рассчитываем налог, если он применим
    const taxAmount = this.interface.tax ? totalCost * this.interface.tax : 0;

    // Обновляем корзину с учетом налога и общей стоимости
    this.interface.cart = {
      ...this.interface.cart,
      tax: taxAmount,
      total: totalCost
    };

    // Обновляем кэшированные данные корзины
    this.interface._cache.cart = {
      ...this.interface._cache.cart,
      tax: taxAmount,
      total: totalCost
    };
  }
  initTotalProducts(): void {
    let totalProducts = 0;
    for (const product of this.interface.cart.products) {
      totalProducts += product.count;
    }
    this.interface.cart = {...this.interface.cart, count: totalProducts};
    this.interface._cache.cart = {...this.interface._cache.cart, count: totalProducts};
  }


  actionCardNumberChange(event): void {
    this.interface._cache.card.number = event.complete;
    if (event.error) {
      this.interface._cache.error_fields.push('number');
      this.interface._cache.error_messages.number = event.error.message;
    } else {
      this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'number');
      this.interface._cache.error_messages.number = '';
    }
  }
  actionCardExpireChange(event): void {
    this.interface._cache.card.expire = event.complete;
    if (event.error) {
      this.interface._cache.error_fields.push('expire');
      this.interface._cache.error_messages.expire = event.error.message;
    } else {
      this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'expire');
      this.interface._cache.error_messages.expire = '';
    }
  }
  actionCardCvcChange(event): void {
    this.interface._cache.card.cvc = event.complete;
    if (event.error) {
      this.interface._cache.error_fields.push('cvc');
      this.interface._cache.error_messages.cvc = event.error.message;
    } else {
      this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'cvc');
      this.interface._cache.error_messages.cvc = '';
    }
  }
  checkPaymentFields(): boolean {
    const _stripe_fields = Object.values(this.interface._cache.card).every(field => field === true);
    const _form_fields = this.actionGrantAddressFields();
    const _user = this.interface.auth;
    return _form_fields && _stripe_fields && _user;
  }

  actionViewCart(): void {
    this.interface._cache.payment = false;
  }
  actionDeleteFromCart(cart_item): void {
    this.store.dispatch(new DeleteProductFromCart(cart_item));
  }
  actionOpenWorkbook(workbook): void {
    this.router.navigate(['/store/product/'+workbook.slug]).then();
  }
  actionGoPayment(): void {
    this.interface._cache.payment = true;
  }
  actionGoCheckout(): void {
    this.router.navigate(['/store/checkout/']).then();
  }
  actionGoCart(): void {
    this.router.navigate(['/store/cart/']).then();
  }
  actionConfirmPayment(): void {
    this.interface.submit = true;
    let _user: any = this.interface.user;
    this.stripeService.createPaymentMethod({
      type: 'card',
      card: this.card.element,
      billing_details: {
        phone: this.interface.user.billing.phone.toString(),
        email: this.interface.user.email
      }
    }).subscribe((result) => {
      if (result.paymentMethod) {
        const stripe_user_data = {
          payment_method_id: result.paymentMethod.id,
          phone: this.interface.user.billing.phone,
          email: this.interface.user.email,
          customer_id: this.interface.payment.stripe.customer_id,
          cart: this.interface._cache.cart,
          shipping: this.interface.user.billing
        }
        this.userService.checkoutStripe(stripe_user_data).subscribe(_checkout => {
          this.interface.submit = false;
          this.interface._cache.payment = false;
          this.interface._cache.payment_complete = true;
          this.interface._cache.payment_order = _checkout;
          this.store.dispatch(new ClearProductsFromCart());
          this.store.dispatch(new updateUser(_user));
        });
      }
      if (result.error) {
        this.interface.submit = false;
      }
    });
  }
  actionClose(): void {
    this.store.dispatch(new ModalCloseAction());
  }




  actionEnterBillingFirstName(event): void {
    this.interface.user.billing = {
      ...this.interface.user.billing,
      first_name: event.target.value
    };
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'billing_first_name');
  }
  actionGrantBillingFirstName(): boolean {
    let _grant = true;
    if (this.interface.user.billing.first_name.length > 0 && this.interface.user.billing.first_name.length < 3) {
      _grant = false;
    }
    if(!_grant) {
      this.interface._cache.error_fields.push('billing_first_name');
    }
    return _grant;
  }

  actionEnterBillingSecondName(event): void {
    this.interface.user.billing = {
      ...this.interface.user.billing,
      last_name: event.target.value
    };
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'billing_last_name');
  }
  actionGrantBillingSecondName(): boolean {
    let _grant = true;
    if (this.interface.user.billing.last_name.length > 0 && this.interface.user.billing.last_name.length < 3) {
      _grant = false;
    }
    if(!_grant) {
      this.interface._cache.error_fields.push('billing_last_name');
    }

    return _grant;
  }


  actionEnterBillingCountry(event): void {
    this.interface.user.billing = {
      ...this.interface.user.billing,
      country: event.target.value
    };
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'billing_country');
  }
  actionGrantBillingCountry(): boolean {
    let _grant = true;
    if (this.interface.user.billing.country.length > 0 && this.interface.user.billing.country.length < 3) {
      _grant = false;
    }
    if(!_grant) {
      this.interface._cache.error_fields.push('billing_country');
    }

    return _grant;
  }


  actionToggleSelectState(): void {
    this.interface._cache.open_select_state = !this.interface._cache.open_select_state;
  }
  actionEnterBillingState(event): void {
    this.interface.user.billing = {
      ...this.interface.user.billing,
      state: event.target.value
    };
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'billing_state');
  }
  actionGrantBillingState(): boolean {
    let _grant = true;
    if (this.interface.user.billing.state.length > 0 && this.interface.user.billing.state.length < 2) {
      _grant = false;
    }
    if(!_grant) {
      this.interface._cache.error_fields.push('billing_state');
    }

    return _grant;
  }
  actionSelectState(state): void {
    this.interface.user.billing = {
      ...this.interface.user.billing,
      state: state.code
    };
    this.actionGrantBillingState();
    this.interface._cache.open_select_state = false;
    this.initTotalCost();
  }
  actionSelectStateNameByCode(state_code: string): string {
    if (!this.interface?.states?.length) {
      return '';
    }

    const state = this.interface.states.find((_state) => _state.code === state_code);
    return state ? state.name : '';
  }

  actionEnterBillingCity(event): void {
    this.interface.user.billing = {
      ...this.interface.user.billing,
      city: event.target.value
    };
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'billing_city');
  }
  actionGrantBillingCity(): boolean {
    let _grant = true;
    if (this.interface.user.billing.city.length > 0 && this.interface.user.billing.city.length < 4) {
      _grant = false;
    }
    if(!_grant) {
      this.interface._cache.error_fields.push('billing_city');
    }

    return _grant;
  }

  actionEnterBillingAddress(event): void {
    this.interface.user.billing = {
      ...this.interface.user.billing,
      address: event.target.value
    };
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'billing_address');
  }
  actionGrantBillingAddress(): boolean {
    let _grant = true;
    if (this.interface.user.billing.address.length > 0 && this.interface.user.billing.address.length < 10) {
      _grant = false;
    }
    if(!_grant) {
      this.interface._cache.error_fields.push('billing_address');
    }

    return _grant;
  }

  actionEnterBillingPostcode(event): void {
    this.interface.user.billing = {
      ...this.interface.user.billing,
      postcode: event.target.value
    };
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'billing_postcode');
  }
  actionGrantBillingPostcode(): boolean {
    let _grant = true;
    if (this.interface.user.billing.postcode > 0 && this.interface.user.billing.postcode < 5) {
      _grant = false;
    }
    if(!_grant) {
      this.interface._cache.error_fields.push('billing_postcode');
    }

    return _grant;
  }

  actionEnterBillingPhone(event): void {
    this.interface.user.billing = {
      ...this.interface.user.billing,
      phone: event.target.value
    };
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'billing_phone');
  }
  actionGrantBillingPhone(check?): boolean {
    let _grant = true;
    if (!this.interface.user.billing.phone || this.interface.user.billing.phone?.toString().length < 12) {
      _grant = false;
    }
    if(!_grant) {
      if(!check) {
        this.interface._cache.error_fields.push('billing_phone');
      }
    }

    return _grant;
  }


  actionGrantAddressFields() :boolean {
    let _grant = true;
    if (this.interface.user.billing.first_name.length < 3) {
      _grant = false;
    }
    if (this.interface.user.billing.last_name.length < 3) {
      _grant = false;
    }
    if (this.interface.user.billing.address.length < 5) {
      _grant = false;
    }
    if (this.interface.user.billing.postcode?.toString().length < 5) {
      _grant = false;
    }
    if (this.interface.user.billing.state.length != 2) {
      _grant = false;
    }
    if (this.interface.user.billing.city.length < 3) {
      _grant = false;
    }

    return _grant;
  };

}
