import {Injectable} from '@angular/core';
import {CanMatch, Route, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MatchPublicPageGradeGuard implements CanMatch {

  constructor() {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const _segment = segments[1]? segments[1].path : segments[0].path;

    return [
      "kindergarten",
      "1st_grade",
      "2nd_grade",
      "3rd_grade",
      "4th_grade",
      "5th_grade",
      "6th_grade",
      "7th_grade",
      "8th_grade",
      "9th_grade",
      "10th_grade",
      "11th_grade",
      "12th_grade"
    ].includes(_segment);
  }

}
