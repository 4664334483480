import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BhMinicartComponent} from "./bh-minicart.component";
import {
    StripeCardCvcComponent,
    StripeCardExpiryComponent,
    StripeCardGroupDirective,
    StripeCardNumberComponent
} from "ngx-stripe";
import {TranslocoModule} from "@ngneat/transloco";
import {RouterLink} from "@angular/router";
import {AppPipesModule} from "../../../app.pipes.module";
import {BhLoginModule} from "../bh-login/bh-login.module";


@NgModule({
  declarations: [BhMinicartComponent],
  exports: [BhMinicartComponent],
    imports: [
        CommonModule,
        NgOptimizedImage,
        StripeCardCvcComponent,
        StripeCardExpiryComponent,
        StripeCardGroupDirective,
        StripeCardNumberComponent,
        TranslocoModule,
        RouterLink,
        AppPipesModule,
        BhLoginModule
    ]
})
export class BhMinicartModule { }
