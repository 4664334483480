import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {Clipboard} from "@angular/cdk/clipboard";
import {UserModel} from "../../../_stores/user/_models/user.model";
import {UserState} from "../../../_stores/user/_state/user.state";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";

@Component({
  selector: 'bh-invite',
  templateUrl: './bh-invite.component.html',
  styleUrls: ['./bh-invite.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'components/invite', alias: 'components.invite' }}]
})
export class BhInviteComponent implements OnInit, OnDestroy {
  interface: {
    user: UserModel,
    invite_link: string,
    _cache: any
  } = {
    user: undefined,
    invite_link: '',
    _cache: {
      subscribe: {
        user: undefined,
      }
    }
  }


  constructor(private store: Store, private cdr: ChangeDetectorRef, private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.initUser();
  }
  ngOnDestroy(): void {
    if (!this.interface._cache) {return}
    Object.keys(this.interface._cache.subscribe).forEach((_subscribe) => {
      if (this.interface._cache.subscribe[_subscribe]) {
        this.interface._cache.subscribe[_subscribe].unsubscribe();
      }
    });
  }

  initUser(): void {
    this.interface._cache.subscribe.user = this.store.select(UserState.selectUser).subscribe((res: any)=> {
      this.interface.user = res;
      this.initInviteLink();
      this.cdr.detectChanges();
    });
  }

  initInviteLink(): void {
    this.interface.invite_link = window.location.origin + '/invite/' + this.interface.user.bin;
  }

  actionClipboardInviteLink(): void {
    let _link = this.interface.invite_link;
    this.clipboard.copy(_link);
    this.cdr.detectChanges();
  }


}
