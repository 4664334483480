import {API} from "../../api";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class WorksheetsService {

  constructor(private http: HttpClient) {
  }

  public getWorksheets() {
    const _url = API.url('worksheets');
    return this.http.get<any[]>(_url).toPromise();
  }

}
