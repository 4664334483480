import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BhCreateMultipasportComponent} from "./bh-create-multipasport.component";
import {BhTooltipModule} from "../bh-tooltip/bh-tooltip.module";
import {AppTranslocoRootModule} from "../../../app.transloco.module";
import {RouterLink} from "@angular/router";



@NgModule({
  declarations: [BhCreateMultipasportComponent],
  exports: [BhCreateMultipasportComponent],
    imports: [
        CommonModule,
        BhTooltipModule,
        AppTranslocoRootModule,
        NgOptimizedImage,
        RouterLink
    ]
})
export class BhCreateMultipasportModule { }
