import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private routerSubscription: Subscription;

  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.viewportScroller.scrollToPosition([0, 0]); // Прокрутка страницы к началу
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe(); // Освобождение ресурсов при уничтожении сервиса
  }
}
