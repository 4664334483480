<div class="bh-leave-review">
    <div *ngIf="!interface.submit" class="bh-form">
        <div class="bh-form-input-group">
            <div class="bh-form-text bh-form-text-xs">Title</div>
            <div class="bh-form-input" [class.bh-form-input-readonly]="interface._cache.update_data" [class.bh-form-input-error]="interface._cache.error_fields.includes('title')">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
                </svg>
                <input name="first_name" type="text" placeholder="Enter title"  [value]="interface.review?.title" (keyup)="actionEnterReviewTitle($event)" (focusout)="actionGrantReviewTitle()" (paste)="actionPasteReviewTitle($event)">
            </div>
        </div>
        <div class="bh-form-input-group">
            <div class="bh-form-text bh-form-text-xs">Review</div>
            <div class="bh-form-textarea" [class.bh-form-textarea-error]="interface._cache.error_fields.includes('description')">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>
                <textarea class="!min-h-[11rem]" rows="3" id="review" name="review" placeholder="Enter review" [value]="interface.review?.description || ''" (keydown)="preventEnter($event)" (focusout)="actionGrantReviewDescription()" (keyup)="actionEnterDescription($event)">{{interface.review?.description || '' }}</textarea>
            </div>
            <div class="bh-form-text bh-form-text-xs">Shift + Enter to new line&nbsp;</div>
        </div>
        <button class="bh-btn bh-btn-sap" (click)="actionAddReview()" [disabled]="!actionGrantAddReview() || interface._cache.update_data">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            <span>Add review</span>
        </button>
    </div>
    <div *ngIf="interface.submit" class="bh-review-submit">
        <img ngSrc="./assets/images/icons/objects/chat.gif" alt="error" height="400" width="400"/>
        <p>Thank you for leaving a review, after checking by the moderator we will add your opinion to the site.</p>
        <button class="bh-btn bh-btn-sap" (click)="actionCloseModal()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            <span>{{'buttons.close' | transloco}}</span>
        </button>
    </div>
</div>
