import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {UserModel} from "../../../_stores/user/_models/user.model";
import {Store} from "@ngxs/store";
import {UserState} from "../../../_stores/user/_state/user.state";
import {TRANSLOCO_SCOPE, TranslocoService} from "@ngneat/transloco";
import {UserService} from "../../../_stores/user/_services/user.service";
import {ModalCloseAction} from "../../../_stores/modal/_actions/modal.actions";
import {AuthUpdateToken} from "../../../_stores/auth/_actions/auth.actions";
import {AuthService} from "../../../_stores/auth/_services/auth.service";
import {UserGeneratorService} from "../../../_services/user.generator.service";

@Component({
  selector: 'bh-change-multipasport',
  templateUrl: './bh-change-multipasport.component.html',
  styleUrls: ['./bh-change-multipasport.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'components/change-multipasport', alias: 'components.change-multipasport'  } }],
})
export class BhChangeMultipasportComponent implements   OnInit, OnDestroy {
  interface: {
    user: UserModel,
    submit: boolean,
    card: {
      email: string,
      password: string,
      accepted: boolean,
      avatar: string,
      first_name: string,
      last_name: string,
      access_level: string,
    }
    _cache: any
  } = {
    user: undefined,
    submit: false,
    card: {
      email: null,
      password: null,
      accepted: false,
      avatar: 'avatar_'+(Math.floor(Math.random() * 29) + 1)+'.svg',
      first_name: '',
      last_name: '',
      access_level: 'guest',
    },
    _cache: {
      upgrade: false,
      upgrade_done: false,
      error_fields: [],
      subscribe: {
        user: undefined,
      }
    }
  }


  constructor(private store: Store, private cdr: ChangeDetectorRef, private authService: AuthService, private transloco: TranslocoService, private generator: UserGeneratorService, private userService: UserService) { }

  ngOnInit(): void {
    this.initUser();
  }

  ngOnDestroy(): void {
    if (!this.interface._cache) {return}
    Object.keys(this.interface._cache.subscribe).forEach((_subscribe) => {
      if (this.interface._cache.subscribe[_subscribe]) {
        this.interface._cache.subscribe[_subscribe].unsubscribe();
      }
    });
  }

  initUser(): void {
    this.interface._cache.subscribe.user = this.store.select(UserState.selectUser).subscribe((_user: any)=> {
      this.interface.user = {..._user};
      this.interface.card = {
        email: this.interface.user.email,
        password: null,
        accepted: true,
        avatar: this.interface.user.avatar,
        first_name: this.interface.user.first_name,
        last_name: this.interface.user.last_name,
        access_level: 'user',
      };
      if(_user._id) {
        this.interface._cache.upgrade_done = true;
      }
      this.cdr.detectChanges();
    });
  }

  actionEnterEmail(event): void {
    this.interface.card.email = event.target.value.toLowerCase();
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'email');
  }
  actionPasteEmail(event): boolean {
    let clipboardData = event.clipboardData;

    let pastedText = clipboardData?.getData('text');
    if(pastedText) {
      this.interface.card.email = pastedText.toLowerCase();
      this.actionGrantEmail();
    }
    return false;
  }
  actionGrantEmail(only_check?: boolean): boolean {
    let _grant = true;
    const _email_rules = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

    if (!_email_rules.test(this.interface.card.email)) {
      if(!only_check) {
        this.interface._cache.error_fields.push('email');
      }
      _grant = false;
    }
    return _grant;
  }

  actionEnterPassword(event): void {
    this.interface.card.password = event.target.value.replace(/[^a-zA-Z0-9!@#$%^&*()]/gi, '',);
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'password');
    this.cdr.detectChanges();
  }
  actionPastePassword(event): boolean {
    let clipboardData = event.clipboardData;

    let pastedText = clipboardData?.getData('text');
    if(pastedText) {
      this.interface.card.password = pastedText;
      this.actionGrantPassword();
    }
    return false;
  }
  actionGeneratePassword(): void {
    this.interface.card.password = this.generator.userPassword();
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'password');
  }
  actionGrantPassword(only_check?: boolean): boolean {
    let _grant = true;
    if (!this.interface.card.password) {
      _grant = false;
    }

    if (this.interface.card.password && this.interface.card.password.length < 8) {
      _grant = false;
    }
    if(!_grant) {
      if(!only_check) {
        this.interface._cache.error_fields.push('password');
      }
    }
    return _grant;
  }


  actionGrantStart(): boolean {
    let _grant = true;
    if (!this.interface.card.email) {
      _grant = false;
    }
    if (this.interface.card.email && this.interface.card.email.length < 6) {
      _grant = false;
    }
    if (!this.interface.card.password) {
      _grant = false;
    }
    if (this.interface.card.password && this.interface.card.password.length < 8) {
      _grant = false;
    }
    if(this.interface.submit) {
      _grant = false;
    }
    return _grant;
  }

  actionIssueCard(): void {
    this.interface.submit = true;
    const _welcome_stars = (Math.floor(Math.random() * 50) + 1);
    const _auth_data = {
      login: this.interface.card.email,
      password: this.interface.card.password
    }
    const _user_data = {
     ...this.interface.user,
      email: this.interface.card.email,
      role: "63c523deb5ae7017443e438f",
      score: {
       ...this.interface.user.score,
        star: {
         spend: (this.interface.user.score.star.spend+_welcome_stars),
          all: (this.interface.user.score.star.all+_welcome_stars),
        }
      }
    }

    this.interface._cache.upgrade = true;
    this.userService.upgrade(_auth_data, _user_data).subscribe({
      next: (user) => {
        this.authService.login(this.interface.card.email, this.interface.card.password).subscribe({
          next: _next => {
            if(_next.statusCode == 200) {
              this.interface.submit = false;
              this.store.dispatch(new AuthUpdateToken(_next.user_data.token));
            }
          },
          error: _error => {}
        });
      }, error: () => {
        this.interface.submit = false;
        this.interface._cache.upgrade = false;
      }
    })
  }

  actionClose(): void {
    this.store.dispatch(new ModalCloseAction());
  }

}
