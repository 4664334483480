<ng-container [ngSwitch]="interface.type">
  <div *ngSwitchCase="'preloader'" class="bh-no-data"></div>
  <div *ngSwitchCase="'preloader-line'" class="bh-no-data bh-no-data-line" ></div>
  <div *ngSwitchCase="'preloader-line-v2'" class="bh-no-data bh-no-data-line-v2" ></div>
  <div *ngSwitchCase="'preloader-workbook'" class="bh-no-data bh-no-data-workbook"></div>
  <div *ngSwitchCase="'preloader-workbook-v2'" class="bh-no-data bh-no-data-workbook-v2"></div>
  <div *ngSwitchCase="'loading'" class="bh-no-data-loading">
      <img ngSrc="/assets/images/animation/logo.gif" alt="loading" height="300" width="300">
    <span>{{text}}</span>
  </div>
  <div *ngSwitchCase="'loading-page'" class="bh-loading-page">
      <img ngSrc="/assets/images/animation/logo.gif" alt="loading" height="300" width="300">
    <span>{{text}}</span>
  </div>
  <div *ngSwitchCase="'no-materials'" class="bh-no-data-materials">
        <img ngSrc="/assets/images/backgrounds/bg_dino_comp.svg" alt="loading" height="300" width="300">
        <span>{{text}}</span>
    </div>
    <div *ngSwitchCase="'no-plans'" class="bh-no-data-materials">
        <img ngSrc="/assets/images/backgrounds/bg_dino_comp.svg" alt="loading" height="300" width="300">
        <span>{{text}}</span>
    </div>

  <div *ngSwitchDefault class="bh-no-data">
    <span>{{text}}</span>
  </div>
</ng-container>
