export class ModalOpenAction  {
  public static readonly type = '[Modal] ModalOpenAction';
  constructor(public data: string, public close?: boolean, public test?: any) {}
}
export class ModalOpenActionWithID  {
  public static readonly type = '[Modal] ModalOpenActionWithID';
  constructor(public data: string, public _id?: string, public close?: boolean) {}
}
export class ModalCloseAction  {
  public static readonly type = '[Modal] ModalCloseAction';
  constructor(public after?: boolean) {}
}

export class ModalOpenActionWithPlan  {
  public static readonly type = '[Modal] ModalOpenActionWithPlan';
  constructor(public data: string, public plan: any) {}
}
export class ModalAfterOpenAction  {
  public static readonly type = '[Modal] ModalAfterOpenAction';
  constructor(public data: string, public close?: boolean, public plan?: any) {}
}
